import { useAbility } from '@casl/react';
import {
	CloudDownload,
	DriveFolderUpload,
	OpenInNew,
	Refresh
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Button,
	LinearProgress,
	Link,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import {
	DataGridPremium,
	GridColDef,
	GridInitialState,
	GridLogicOperator,
	GridRenderCellParams,
	GridToolbarContainer,
	useGridApiContext,
	useGridApiRef,
} from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers';
import {
	isAfter,
} from 'date-fns';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';

import { useIntl } from 'react-intl';
import { useVendorApi } from '../../services/vendor-api';
import {
	getPreviousThursday,
} from '../../utils';
import { AbilityContext } from '../AbilityContext';
import ErrorDisplay from '../ErrorDisplay';
import useGridHelper from '../hooks/GridHelper';
import PropertyWeeklyPartnerReport from '../Property/Reports/PropertyUpdate/PropertyWeeklyPartnerReport';
import {
	ReportAction,
	ReportState
} from '../Reports/types';
import { PartnerReportGridCell } from './PartnerReportGridCell';
import { ReportStateChip } from '../Reports/ReportStateChip';
import Tile from '../Tile';
import { usePartnerContext } from './PartnerContext';
import {
	PartnerProperty,
	PartnerReportsPackageRequest
} from './types.js';
import dayjs from 'dayjs';
import './styles.css';

const initialGridState: GridInitialState = {
	columns: {
		columnVisibilityModel: {
			id: false,
			name: true,
			marketReportLink: true,
			weeklyUpdate: true,
		},
	},
	filter: {
		filterModel: {
			items: [],
			logicOperator: GridLogicOperator.And,
			quickFilterValues: [],
			quickFilterLogicOperator: GridLogicOperator.And
		},
	},
	sorting: {
		sortModel: [
			{
				field: 'name',
				sort: 'asc'
			} ],
	}
};

const PartnerProperties = ( { editable = false } ) => {
	const intl = useIntl();
	const { valueGetter, formatDateTime } = useGridHelper();
	const handleError = ErrorDisplay();
	const { getPartnerProperties, packageVendorReports, isLoading } = useVendorApi( handleError );
	const [ refresh, setRefresh ] = React.useState( true );
	const [ rows, setRows ] = useState<PartnerProperty[]>( [] );
	const { partner } = usePartnerContext();
	const apiRef = useGridApiRef();
	const [date, setDate] = useState<any>(dayjs(getPreviousThursday(new Date())));
	const ability = useAbility( AbilityContext );
	const canCreate=ability.can( 'create', 'PropertyWeeklyUpdateReport' );
	const canUpdate=ability.can( 'update', 'PropertyWeeklyUpdateReport' );
	const canDelete=ability.can( 'delete', 'PropertyWeeklyUpdateReport' );
	const canPublish=ability.can( 'publish', 'PropertyWeeklyUpdateReport' );

	interface CustomToolbarProps {
		onDateChange: any,
		date: any
	}
	const handlePropertyWeeklyReportUpdated = async (data: ReportState, action:ReportAction)=>{
		setRefresh(true);
	};

	const CustomToolbar:React.FC<CustomToolbarProps> = () => {
		const intl = useIntl();
		const apiRef = useGridApiContext();
		const { enqueueSnackbar, closeSnackbar } = useSnackbar();
		const handleDownload = useCallback(()=>{
			const dateString = date.format('YYYYMMDD');
			const rows = apiRef.current.getSelectedRows().values();
			const data: PartnerReportsPackageRequest = {
				partner: {
					id: partner.id,
					name: partner.name
				},
				properties: Array.from(rows).map((r)=>{
					return {
						id: r.id,
						name: r.name,
						marketReportLink: r.marketReportLink,
						weeklyUpdate: r.weeklyUpdate
					};
				}),
				date: dateString
			};
			packageVendorReports(data)
				.then((result)=>{
					enqueueSnackbar('Download Ready!',
						{
							variant: 'success',
							autoHideDuration: null,
							anchorOrigin: {
								vertical: 'top',
								horizontal:'right',
							},
							action: (snackbarId)=>(<>
								<IconButton
									target='_blank'
									href={result?.url}><OpenInNew/>
								</IconButton>
								<IconButton
									size="small"
									aria-label="close"
									color="inherit"
									onClick={ () => closeSnackbar( snackbarId ) }
								><CloseIcon fontSize="small" />
								</IconButton>
							</>)
						}
					);
				} );
		}, [apiRef, closeSnackbar, enqueueSnackbar] );

		return (
			<GridToolbarContainer>
				<DatePicker
					sx={{fontSize: 'small'}}
					onChange={setDate} value={date}
					shouldDisableDate={(date:any) => {
						const day = date?.day(); // Get the day of the week (0 for Sunday)
						return day !== 4; // Disable all dates except Thursday (day !== 4)
					}}
				/>
				<Button
					size="small"
					startIcon={ <Refresh /> }
					onClick={ ()=>setRefresh(true) }
					title={ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh Grid' }) }
				>
					{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
				</Button>
				<Button
					size="small"
					startIcon={ <DriveFolderUpload /> }
					onClick={ handleDownload }
					disabled={!editable || isLoading || (apiRef?.current?.getSelectedRows()?.size === 0 ?? true)}
					title={ intl.formatMessage({ id: 'package_reports', defaultMessage: 'Package selected community reports for download.' }) }
				>
					{ intl.formatMessage({ id: 'package', defaultMessage: 'Package' }) }
				</Button>
			</GridToolbarContainer>
		);
	};

	useEffect( () => {
		if ( refresh && partner?.id ) {
			setRefresh( false );
			apiRef.current.setRowSelectionModel([]);
			const dateString = date.format('YYYY-MM-DDThh:mm:ss');
			getPartnerProperties( partner?.id, dateString )
				.then( ( data: PartnerProperty[] ) => {
					setRows( data ?? [] );
				} );
		}
	}, [refresh, partner, date, apiRef] );

	const columns = useMemo<GridColDef<PartnerProperty>[]>( () => [
		{
			field: 'id',
			headerName: 'Property Id',
			hideable: true,
			renderCell: ( params: GridRenderCellParams<PartnerProperty> ) => {
				const { row } = params;
				return ( <Link href={ `/communities/${ row?.id }` }>{ row.name }</Link> );
			}
		},
		{
			field: 'name',
			headerName: 'Community',
			flex:1,
			hideable: false,
			renderCell: ( params: GridRenderCellParams<PartnerProperty> ) => {
				const { row } = params;
				return ( <Link href={ `/communities/${ row?.id }` }>{ row.name }</Link> );
			}
		},

		{
			field: 'marketReportLink',
			description: 'Link to download Market Reports ',
			headerName: '',
			flex:.4,
			hideable: false,
			renderCell: ( params:  GridRenderCellParams<PartnerProperty>  ) => {
				const { row } = params;
				return (row?.marketReportLink?.href  && <>
					<LoadingButton href={ row?.marketReportLink?.href } target="_blank" title={ `Download ${row?.marketReportLink?.label}` }>
						<CloudDownload />
					</LoadingButton>
					{/*{ formatDateTime({...params, field: 'marketReportLink.updatedAt', value: row.marketReportLink?.updatedAt})}*/}
				</>);
			}
		},
		{
			field: 'marketReportLink.updatedAt',
			headerName: 'Updated',
			description: 'Timestamp when Market Report last updated',
			flex:.6,
			hideable: false,
			valueGetter,
			type:'dateTime',
			valueFormatter: formatDateTime,
		},
		{
			field: 'weeklyUpdate',
			headerName: '',
			flex:.5,
			hideable: false,
			renderCell: ( params:  GridRenderCellParams<PartnerProperty>  ) => {
				const { row } = params;
				const { weeklyUpdate: report } = row;
				return (report?.id && <PartnerReportGridCell
					key={`ReportCard-weekly-update-${report.id}`}
					report={ report }
					component={ (props:any)=>(<PropertyWeeklyPartnerReport
						{ ...props }
						property={row}
						initialState={row.weeklyUpdate}
					/>) }
					onChange={ handlePropertyWeeklyReportUpdated }
					canCreate={canCreate}
					canDelete={canDelete}
					canPublish={canPublish}
					canUpdate={canUpdate}
				/>
				);
			}
		},
		{
			field: 'weeklyUpdate.updatedAt',
			headerName: 'Updated',
			description: 'Timestamp when weekly update last updated/submitted',
			flex:.6,
			type: 'dateTime',
			valueFormatter: formatDateTime,
			valueGetter: (p)=>get(p.row, p.colDef.field),
			hideable: false,
		},
		{
			field: 'weeklyUpdate.updatedBy.name',
			headerName: 'Updated By',
			description: 'User who last updated/submitted the weekly update',
			flex:.6,
			valueGetter: (p)=>get(p.row, p.colDef.field),
			hideable: false,
		},
		{
			field: 'weeklyUpdate.state',
			headerName: 'State',
			description: 'Current status of the weekly update',
			flex:.6,
			valueGetter,
			renderCell: ( { value })=>value && <ReportStateChip state={ value }/>,
			hideable: false,
		},
		{
			field: 'weeklyUpdate.driveLink.updatedAt',
			description: 'Timestamp when Partner Report was last published',
			headerName: 'Published',
			flex:.6,
			valueGetter,
			type:'dateTime',
			valueFormatter: formatDateTime,
			hideable: false,
			renderCell: ( params )=> {
				const { value, formattedValue } = params; //publishedAt
				const { updatedAt } = params.row.weeklyUpdate ?? {};
				let stale = true;
				if (updatedAt && value) {
					stale = isAfter(new Date(updatedAt), new Date(value));
				}
				return formattedValue && stale ?<span className='isStalePublish' title='Published report may be stale'>{ formattedValue }</span> : formattedValue;
			},
		},
	], [formatDateTime, valueGetter] );


	useEffect( () => {
		if ( refresh && partner?.id ) {
			setRefresh( false );
		}
	}, [ refresh, partner ] );

	return (
		<Tile
			title={ intl.formatMessage( {
				id: 'partner_community_reports',
				defaultMessage: 'Community Reports',
			})}
			sx={ { height: 'calc(100vh - 300px)'} }
		>
			<DataGridPremium
				experimentalFeatures={{ columnGrouping: true }}
				loading={isLoading}
				apiRef={ apiRef }
				rows={ rows }
				columns={ columns }
				initialState={ initialGridState }
				// onStateChange={ handleGridStateUpdated }
				disableRowGrouping
				pagination={ true }
				paginationMode={ 'client' }
				checkboxSelection
				disableRowSelectionOnClick {...rows}
				// getRowClassName={(params) => {
				// 	const { updatedAt, driveLink: { updatedAt: publishedAt } = { updatedAt: ''} } = params.row.weeklyUpdate ?? {};
				// 	return  publishedAt && isAfter(new Date(publishedAt), new Date(updatedAt)) ? 'isPublishedReport' : '';
				// }}
				columnGroupingModel={[
					{
						groupId: 'Market Reports',
						headerAlign: 'center',
						headerClassName:'columnGroupingHeader',
						children: [
							{ field: 'marketReportLink' },
							{ field: 'marketReportLink.updatedAt' },
						],
					},
					{
						groupId: 'Partner Report',
						headerAlign: 'center',
						headerClassName:'columnGroupingHeader',
						children: [
							{ field: 'weeklyUpdate' },
							{ field: 'weeklyUpdate.updatedAt' },
							{ field: 'weeklyUpdate.updatedBy.name' },
							{ field: 'weeklyUpdate.state' },
							{ field: 'weeklyUpdate.driveLink.updatedAt' }
						],
					},
				]}
				slots={ {
					toolbar: CustomToolbar,
					loadingOverlay: LinearProgress,
				} }
			/>

		</Tile>
	);
};

export default PartnerProperties;
