import styled from '@emotion/styled';
import {
	Business,
	CloudUpload,
	Flag,
	People,
	Publish,
	Refresh,
	Update,
} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	InputLabel,
	ListItem,
	ListItemText,
	Paper,
	Rating,
	RatingProps,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { CalendarIcon } from '@mui/x-date-pickers';
import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import {
	ReportTemplate,
	useReportingApi
} from '../../../../services/reporting-ui-api';
import DisplayField from '../../../DisplayField';
import ErrorDisplay from '../../../ErrorDisplay';
import LookTileContent from '../../../Looker/LookTileContent';
import { ReportContext } from '../../../Reports/ReportContext.js';
import { ReportState } from '../../../Reports/types';
import Tile from '../../../Tile';
import { PropertyContext } from '../../PropertyContext.js';
import PropertyStaffingContent, { PropertyStaffingType } from '../../PropertyStaffing';
import {
	MultilineTextInputField,
	TextInputFieldProps
} from './MultilineTextInputField';
import { PropertyReportProps } from './types';
// import './print-styles.css';

export interface PropertyWeeklyStatusReportState {
		communityName: string,
		market: string,
		communityManager: string,
		unitCount: number,
		bedCount: number,
		retailArea: number,
		propertyGroup: string,
		partner: string,
		lender: string,
		preleaseNumbers: any,
		weeklyLeasingStats1: any,
		weeklyLeasingStats2: any,
		cyPreleasePercentages: any,
		cyPreleaseNumbers: any
		preleaseYoYComparison: any,
		marketComparisonChart: any,
		marketComparisonTable: any,
		residentEvents: string,
		marketOutreach: string,
		marketInsight: string,
		marketInsightInternal: string,
		currentConcessions: string,
		operationsInsight: string,
		internalOperationsInsight: string,
		leasingRecommendation: string,
		staffing: PropertyStaffingType,
		staffingComment: string,
		internalStaffing: string,
		status: string,
		mcSupport: string,
		overallPerformance: string,
		rateCommentary: string,
		rejectionFeedback: string,
		keyDates: string,
		successes: string,
		challenges: string,
		actionItemUpdates: string,
		newActionItems: string
		recommendations: string,
		watchlistState: string,
		watchlistReason: string,
		strategy: string,
		personnel: string,
		trending: any,
		weeklyMarketShare:any,
		goals:any,
		rating?: number
}

interface PropertyWeeklyUpdateProps extends PropertyReportProps { }

const TYPE = 'Property';
const NAME = 'weekly_update';
const VERSION = 1;

interface RatingInputProps extends RatingProps {
	label: string
}
const RatingInput: React.FC<RatingInputProps> = ( { label, onChange, ...props }) => {
	const [value, setValue] = React.useState<number | null>(props.value ?? null);
	const [hover, setHover] = React.useState(props.value);
	const handleChange=(event:any, newValue: number|null)=>{
		newValue && value !== newValue && setValue(newValue);
	};
	const onChangeActive=(event:any, newHover:number) => {
		setHover(newHover);
	};

	useEffect( () => {
		const target = {id: props.id, value: value};
		// @ts-ignore
		onChange && onChange({target}, value);
	}, [onChange, props.id, value] );
	return (
		<Box sx={ { display: 'flex', alignItems: 'center' }}>
			<InputLabel htmlFor={props.name}>{label}</InputLabel>
			<Rating
				{ ...props }
				onChange={handleChange}
				onChangeActive={onChangeActive}
			/>
			<Box sx={ { ml: 2 } }>{ (hover && hover > -1) ? hover : props.value }</Box>
		</Box>);
};

const Section: React.FC<any> = ({ title, subTitle, icon, children }) => {
	return (
		<Paper sx={{ width: '100%' }} elevation={2} className={'page-break'}>
			<Grid
				item
				xs={12}
				sx={{
					margin: '4px',
					padding: 1,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between', // Ensures text is left and icon is right
						alignItems: 'center', // Vertically aligns text and icon
					}}>
					<Typography variant="h5">{title}</Typography>{icon}
				</Box>
				<Typography variant="body2">{subTitle}</Typography>
				<Grid
					container
					spacing={0.2}
					justifyContent="center"
					alignItems="stretch"
				>
					{children}
				</Grid>
			</Grid>
		</Paper>
	);
};

// SectionPanel Component
const SectionPanel: React.FC<any> = ({ title, children, icon, sx }) => {
	return (
		<Grid
			item
			xs={6}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				flex: 1,
				alignSelf: 'stretch',
			}}
		>
			<Card
				sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				{title && <CardHeader title={title} action={icon} />}
				<CardContent
					sx={{ flex: 1, alignSelf: 'stretch', padding: 1, ...sx }}>{children}</CardContent>
			</Card>
		</Grid>
	);
};

const InternalStyledCard = styled(Card)(({ theme }) => ({
	//@ts-ignore
	// backgroundColor: theme.palette.mode === 'dark' ? '#263238' : '#eceff1',
	//@ts-ignore
	...theme.typography.body2,
	//@ts-ignore
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
}));

const ExternalStyleCard = styled(Card)(({ theme }) => ({
	//@ts-ignore
	// backgroundColor: theme.palette.mode === 'dark' ? '#880e4f' : '#ffebee',
	backgroundColor: theme.palette.mode === 'dark' ? '#2196F31A' : '#2196F31A',
	//@ts-ignore
	...theme.typography.body2,
	//@ts-ignore
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
	marginTop: '8px',
	boxSizing: 'none',
	border: '1px solid transparent',
}));

const WatchlistStatusCard = styled(Card)(({ theme }) => ({
	//@ts-ignore
	backgroundColor: theme.palette.mode === 'dark' ? '#004d40' : '#b2dfdb',
	//@ts-ignore
	...theme.typography.body2,
	//@ts-ignore
	color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
	marginTop: '8px'
}));

export const PropertyWeeklyUpdateHelpContent = (props: any) => {
	const InlineSaveIcon = styled(SaveIcon)(({ theme }) => ({
		fontSize: '1rem',
		verticalAlign: 'middle',
		// @ts-ignore
		marginLeft: theme.spacing(0.5),  // Slight space between text and icon
	}));
	const InlinePublishIcon = styled(Publish)(({ theme }) => ({
		fontSize: '1rem',
		verticalAlign: 'middle',
		// @ts-ignore
		marginLeft: theme.spacing(0.5),  // Slight space between text and icon
	}));
	const InlineUploadIcon = styled(CloudUpload)(({ theme }) => ({
		fontSize: '1rem',
		verticalAlign: 'middle',
		// @ts-ignore
		marginLeft: theme.spacing(0.5),  // Slight space between text and icon
	}));
	const InlineRefreshIcon = styled(Refresh)(({ theme }) => ({
		fontSize: '1rem',
		verticalAlign: 'middle',
		// @ts-ignore
		marginLeft: theme.spacing(0.5),  // Slight space between text and icon
	}));
	return (
		<Box sx={{ p: 2, maxWidth: 300 }}>
			<Typography variant="h6" gutterBottom>
				Workflow Instructions
			</Typography>
			<List>
				<ListItem>
					<ListItemText
						primary={<strong>{ 'CM\'s:' }</strong>}
						secondary={
							<span>
                    Provide Internal and External input values and Save
								<InlineSaveIcon />
							</span>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={<strong>Regional Manager:</strong>}
						secondary={
							<span>Review CM input, provide Regional Manager input, then Save<InlineSaveIcon /> and Submit<InlinePublishIcon/></span>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={<strong>Asset Management:</strong>}
						secondary={
							<span>Review and possibly tweak External inputs then Publish<InlineUploadIcon/> (via Partners)</span>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						primary={<strong>Metrics/Data:</strong>}
						secondary={
							<span>If the data appears incorrect or incomplete, click Refresh<InlineRefreshIcon/> to update those.</span>
						}
					/>
				</ListItem>
			</List>
		</Box>
	);
};

export const PropertyWeeklyUpdate: React.FC<PropertyWeeklyUpdateProps> = ( { canUpdate, onChange, ...props }) => {
	const reportingApi = useReportingApi(ErrorDisplay);
	const [property] = useContext(PropertyContext);
	const [report] = useContext(ReportContext);
	const [template, setTemplate] = useState<ReportTemplate>();
	const [state, setState, ] = useState<PropertyWeeklyStatusReportState>( report.data );
	const [readonly, setReadonly] = useState<boolean>(!!report.published);
	const updateState = useCallback((newState: Partial<PropertyWeeklyStatusReportState>) => {
		setState((prevState)=>{
			return {
				...prevState,
				...newState
			};
		});
		onChange( { data: newState });
	},[onChange]);

	const handleOnChange = useCallback((event: any)=>updateState( { [event.target.id]: event.target.value } ),[]);

	useEffect( () => {
		setReadonly(!!report.submitted);
	}, [report.submitted] );

	useEffect( () => {
		setState(report.data);
	}, [report] );

	useEffect(()=>{
		if (!template) {
			reportingApi.getReportTemplate( TYPE, NAME, VERSION )
				.then( newTemplate => {
					setTemplate( newTemplate );
				} );
		}
	}, []);

	useEffect( () => {
		let newState: Partial<ReportState> = {
			type: TYPE,
		};
		if (template?.id) {
			newState = {
				...newState,
				templateId: template.id,
				// version: template.version,
			};
		}
		onChange(newState);
	}, [onChange, template] );

	const CommentaryProps: TextInputFieldProps = {
		variant: 'standard',
		rows: 3,
		fullWidth: true,
		required: true,
		size: 'small',
		sx: {marginTop: '16px'},
		onChange: handleOnChange,
		inputProps: { readOnly: !canUpdate || readonly }
	};
	const ExternalCommentaryProps: TextInputFieldProps = {
		...CommentaryProps,
		maxRows: 3 ,
		maxLength: 120,
		inputProps: { ...CommentaryProps.inputProps, maxLength: 120 }
	};
	const InternalCommentaryProps: TextInputFieldProps = {
		...CommentaryProps,
		maxLength: 400,
		inputProps: { ...CommentaryProps.inputProps, maxLength: 400 },
	};
	return ( property && template &&
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			sx={{
				// height: 'calc(100vh - 110px)', // Full viewport height to center vertically
				backgroundColor: 'lightgrey',
				padding: 2,
			}}
			className='print-container'
		>
			<Grid item sm={6} md={8} lg={11}>
				{/* Header Info */}
				<Grid container justifyContent="space-between">
					<Grid item><DisplayField
						key='market' id='market' label='Market' sx={{fontSize:'.5em'}}
						value={state?.market}
						onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='cm' id='cm' label='Community Manager'
						value={state?.communityManager} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='unitCount' id='unitCount' label='# Units'
						value={state?.unitCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='bedCount' id='bedCount' label='# Beds'
						value={state?.bedCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='retailArea' id='retailArea' label='Retail SqFt.'
						value={state?.retailArea} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='reportingGroup' id='reportingGroup' label='Group'
						value={state?.propertyGroup} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='partner' id='partner' label='Partner'
						value={state?.partner} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='lender' id='lender' label='Lender'
						value={state?.lender} onChange={handleOnChange}
					/></Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					sx={{width:'100%', height: 'calc(100vh - 170px)', overflow: 'auto'}}
				>
					<Section key='weekly_leasing_section' title='Weekly Leasing' icon={<Update/>}>
						<SectionPanel key='weekly_leasing_panel_metrics' title='' sx={{alignSelf: 'stretch'}}>
							<Tile title='Weekly Leasing Stats' sx={{height: 'fit-content', fontSize:'smallest', marginBottom:'2px'}} elevation={0}>
								<LookTileContent
									key='weeklyLeasingStats1'
									id='weeklyLeasingStats1'
									lookId={template?.config?.weeklyLeasingStats1?.lookId}
									value={state?.weeklyLeasingStats1}
									sx={ {height: '190px'} }
									filters={ {
										'subject_and_competitor_leasing.community': state?.communityName
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
								<LookTileContent
									key='weeklyLeasingStats2'
									id='weeklyLeasingStats2'
									lookId={template?.config?.weeklyLeasingStats2?.lookId}
									value={state?.weeklyLeasingStats2}
									sx={ {height: '140px'} }
									filters={ {
										'lease_diary_property_units.community_for_filter': state?.communityName,
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
							</Tile>
							<Tile title='Trending' sx={{ height: 'fit-content', fontSize:'smallest', marginBottom:'2px' }} elevation={0}>
								<LookTileContent
									id='trending'
									lookId={'135'}
									value={state?.trending}
									sx={ {height: '40px'} }
									filters={ {
										'lease_diary_property_units.community': state?.communityName
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/></Tile >
							<Tile title='Wk Market Share' sx={{ height: 'fit-content', fontSize:'smallest', marginBottom:'2px' }} elevation={0}>
								<LookTileContent
									id='weeklyMarketShare'
									lookId={'136'}
									rowLimit={1}
									value={state?.weeklyMarketShare}
									sx={ {height: '60px'} }
									filters={ {
										'subject_and_competitor_leasing.community': state?.communityName
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/></Tile>
							<Tile title='Goals' sx={{ height: 'fit-content', fontSize:'smallest', marginBottom:'2px' }} elevation={0}>
								<LookTileContent
									id='goals'
									lookId={'137'}
									value={state?.goals}
									sx={ {height: '60px'} }
									rowLimit={1}
									filters={ {
										'lease_diary_property_units.community': state?.communityName
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
							</Tile>
						</SectionPanel>
						<SectionPanel sx={{alignSelf: 'stretch', height: 'fit-content'}}>
							<Tile title='Internal' StyledCard={InternalStyledCard} elevation={0} sx={{height: 'fit-content', alignItems: 'space-around'}}>
								<RatingInput
									id='rating'
									name='rating'
									label='Rating'
									value={state?.rating}
									onChange={handleOnChange}
									size='large'
									precision={.1}
								/>
								<MultilineTextInputField
									key='successes' id='successes' label='Successes' sx={{fontSize:'.5em'}}
									defaultValue={state?.successes}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='challenges' id='challenges' label='Challenges' sx={{fontSize:'.5em'}}
									defaultValue={state?.challenges}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='rejectionFeedback' id='rejectionFeedback' label='Feedback from non-leasing prospects' sx={{fontSize:'.5em'}}
									defaultValue={state?.rejectionFeedback}
									{ ...InternalCommentaryProps }
								/>
							</Tile>
						</SectionPanel>
					</Section>
					<Section title='Overall Leasing' icon={<CalendarIcon />} elevation={0}>
						<SectionPanel sx={{alignSelf: 'stretch'}}>
							<Tile title='Prelease Stats' sx={{ height: 'fit-content', fontSize:'smallest' }} elevation={0}>
								<LookTileContent
									id='cyPreleasePercentages'
									lookId={template?.config?.cyPreleasePercentages?.lookId}
									value={state?.cyPreleasePercentages}
									sx={ {height: '60px'} }
									filters={ {
										'lease_diary_property_units.community_filter': state?.communityName
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
								<LookTileContent
									id='cyPreleaseNumbers'
									lookId={template?.config?.cyPreleaseNumbers?.lookId}
									value={state?.cyPreleaseNumbers}
									sx={ {height: '60px'} }
									filters={ {
										'lease_diary_property_units.community_filter': state?.communityName,
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
							</Tile>
							<Tile title='Prelease YoY Comparison' sx={{height: 'fit-content', width: '100%', fontSize:'smallest'}} elevation={0}>
								<LookTileContent
									id='preleaseYoYComparison'
									lookId={template?.config?.preleaseYoYComparison?.lookId}
									value={state?.preleaseYoYComparison}
									type='image'
									// sx={ {height: '340px'} }
									imageHeight={300}
									// imageWidth={400}
									filters={ {
										'leasing_velocity_cy_vs_py.community': state?.communityName,
									} }
									readonly={readonly}
									onChange={handleOnChange}
								/>
							</Tile>
						</SectionPanel>
						<SectionPanel sx={{alignSelf: 'stretch', padding: 1}}>
							<Tile title='Internal' StyledCard={InternalStyledCard} sx={{height: 'fit-content'}} elevation={0}>
								<MultilineTextInputField
									key='overallPerformance' id='overallPerformance' label='Overall Performance Commentary'
									defaultValue={state?.overallPerformance}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='actionItemUpdates' id='actionItemUpdates' label='Updates on Prior Week Action Items'
									defaultValue={ state?.actionItemUpdates }
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='newActionItems' id='newActionItems' label='New Action Items' sx={{fontSize:'.5em'}}
									defaultValue={state?.newActionItems}
									onChange={ handleOnChange }
									{ ...InternalCommentaryProps }
								/>
							</Tile>
							<Tile title='External' StyledCard={ExternalStyleCard} sx={{height: 'fit-content'}} elevation={0}>
								<MultilineTextInputField
									id="status"
									label="Follow Up Items & Status"
									defaultValue={ state?.status }
									{ ...ExternalCommentaryProps }
								/>
							</Tile>
						</SectionPanel>

					</Section>
					<Section title='Market, Marketing and Rates' icon={<Business/>}>
						{/* Market Comparison */}
						<SectionPanel sx={{alignSelf: 'stretch'}}>
							<Tile title='Market Comparison' sx={{height: 'fit-content' }} elevation={0}>
								<LookTileContent
									id='marketComparisonChart'
									lookId={template?.config?.marketComparisonChart?.lookId}
									title='Market Comparison'
									value={state?.marketComparisonChart}
									sx={ {height: '360px', alignItems: 'right'} }
									//imageWidth={460}
									imageHeight={355}
									type='image'
									filters={ {
										'subject_and_competitor_leasing.community': state?.communityName,
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
								<LookTileContent
									id='marketComparisonTable'
									lookId={ template?.config?.marketComparisonTable?.lookId } // '120' }
									title='Market Comparison'
									value={state?.marketComparisonTable}
									// sx={ {height: '360px'} }
									// imageWidth={460}
									imageHeight={100}
									cropHeight={50}
									cropWidth={650}
									type='image'
									filters={ {
										'subject_and_competitor_leasing.community': state?.communityName,
									} }
									readonly={readonly}
									onChange={ handleOnChange }
								/>
							</Tile>
						</SectionPanel>
						<SectionPanel sx={{alignSelf: 'stretch'}}>
							<Tile title='Internal' StyledCard={InternalStyledCard} sx={{height: 'fit-content', fontSize:'smallest'}} elevation={0}>
								<MultilineTextInputField
									key='marketInsightInternal' id='marketInsightInternal' label='Market Insight'
									defaultValue={state?.marketInsightInternal}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='keyDates' id='keyDates' label='Key Dates & Market Outreach'
									defaultValue={state?.keyDates}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='rateCommentary' id='rateCommentary' label='Rate/Special Commentary'
									defaultValue={state?.rateCommentary}
									{ ...InternalCommentaryProps }
								/>
							</Tile>
							<Tile title='External'  StyledCard={ExternalStyleCard} sx={{height: 'fit-content', fontSize:'smallest'}} elevation={0}>
								<MultilineTextInputField
									key='marketInsight'
									id='marketInsight'
									label='Market Insight'
									defaultValue={state?.marketInsight}
									{ ...ExternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='marketOutreach'
									id='marketOutreach'
									label='Key Dates & Market Outreach'
									defaultValue={state?.marketOutreach}
									{ ...ExternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='currentConcessions'
									id='currentConcessions'
									label='Current Concessions'
									defaultValue={state?.currentConcessions}
									{ ...ExternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='leasingRecommendation'
									id='leasingRecommendation'
									label='Recommendation'
									defaultValue={state?.leasingRecommendation}
									{ ...ExternalCommentaryProps }
								/>
							</Tile>
						</SectionPanel>
					</Section>
					<Section title='Staffing & Operations' icon={<People/>}>
						<SectionPanel sx={{alignSelf: 'stretch'}}>
							<Tile title="Current Staffing" sx={ { height: 'fit-content', fontSize: 'smallest' } } elevation={0}>
								<PropertyStaffingContent
									id="staffing"
									property={ property }
									sx={ { fontSize: 'smaller' } }
									value={ state?.staffing }
									canUpdate={canUpdate}
									onChange={ handleOnChange }
									readonly={readonly}
								/>
							</Tile>

						</SectionPanel>
						<SectionPanel sx={{alignSelf: 'stretch'}}>
							<Tile title='Internal' sx={{height: 'fit-content', fontSize:'smallest'}} StyledCard={InternalStyledCard} elevation={0}>
								<MultilineTextInputField
									key='internalStaffing' id='internalStaffing' label='Staffing Commentary'
									defaultValue={state?.internalStaffing}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='mcSupport' id='mcSupport' label='MC Support/Training'
									defaultValue={state?.mcSupport}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='internalOperationsInsight' id='internalOperationsInsight' label='Facilities, Maintenance & Operations'
									defaultValue={state?.internalOperationsInsight}
									{ ...InternalCommentaryProps }
								/>
							</Tile>
							<Tile title='External' sx={{height: 'fit-content', fontSize:'smallest'}} StyledCard={ExternalStyleCard} elevation={0}>
								<MultilineTextInputField
									id="staffingComment"
									label="Staffing Commentary"
									defaultValue={ state?.staffingComment ?? '' }
									{ ...ExternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='operationsInsight'
									id='operationsInsight'
									label='Facilities, Maintenance & Operations'
									defaultValue={state?.operationsInsight}
									{ ...ExternalCommentaryProps }
									maxRows={5}
									rows={5}
									maxLength={400}
									inputProps={{ ...CommentaryProps.inputProps, maxLength: 400 }}
								/>
							</Tile>
						</SectionPanel>
					</Section>
					<Section title='Executive Summary' icon={<Flag/>}>
						<SectionPanel sx={{alignSelf: 'stretch' }}>
							<Tile sx={{height: '100%', fontSize:'smallest', padding: 2}} elevation={0}>
								<DisplayField
									key='watchlistState' id='watchlistState' label='State'
									value={state?.watchlistState ?? property?.watchlist?.state}
									onChange={handleOnChange}
								/>
								<DisplayField
									key='market' id='watchlistReason' label='Main Concern'
									value={state?.watchlistReason ?? property?.watchlist?.reason}
									onChange={handleOnChange}
								/>
							</Tile>
						</SectionPanel>
						<SectionPanel sx={{alignSelf: 'stretch', }}>
							<Tile title='Regional Manager Input' sx={{height: 'fit-content', fontSize:'smallest'}} StyledCard={WatchlistStatusCard} elevation={0}>
								<MultilineTextInputField
									key='strategy' id='strategy' label='Performance & Strategy'
									defaultValue={state?.strategy}
									{ ...InternalCommentaryProps }
								/>
								<MultilineTextInputField
									key='personnel' id='personnel' label='Personnel Concerns'
									defaultValue={state?.personnel}
									{ ...InternalCommentaryProps }
								/>
							</Tile>
						</SectionPanel>
					</Section>
				</Paper>
			</Grid>
		</Grid>	);
};

export default PropertyWeeklyUpdate;
