import {
	CloudDownload,
	CloudUpload,
	Preview,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
	useCallback,
	useState
} from 'react';
import * as React from 'react';
import { useReportingApi } from '../../services/reporting-ui-api';
import {
	getPreviousThursday,
	isWeekAfter
} from '../../utils';
import ErrorDisplay from '../ErrorDisplay.js';
import { PropertyWeeklyUpdateHelpContent } from '../Property/Reports/PropertyUpdate/PropertyWeeklyUpdate';
import ReportDialog from '../Reports/ReportDialog';
import {
	ReportAction,
	ReportState
} from '../Reports/types';
import { PartnerReportState } from './types';


interface ReportGridCellProps {
	report: PartnerReportState,
	onChange?: ( state: ReportState, action: ReportAction)=>void
	component: any
	isNew?: boolean
	canCreate?: boolean,
	canUpdate?: boolean,
	canDelete?: boolean,
	canPublish?: boolean
}

export const PartnerReportGridCell:React.FC<ReportGridCellProps> = ( {
	report,
	component,
	onChange,
	canUpdate = true,
	canDelete = true,
	canPublish = true
} ) => {
	const reportingApi = useReportingApi(ErrorDisplay);
	const { enqueueSnackbar } = useSnackbar();
	const [showReportDialog, setShowReportDialog] = useState(false);
	const [lastDialogAction, setLastDialogAction] = useState<{ state: ReportState, action: ReportAction }>({state:report, action:'cancel'});
	const handleCloseReportDialog = async () => {
		setShowReportDialog( false );
		const {state, action} = lastDialogAction;
		onChange && onChange(state, action);
	};

	const handleEditClick = useCallback(()=>{
		setShowReportDialog(true);
	},[]);

	const handleUpload = async ()=>{
		const newState = await reportingApi.publishReport( report );
		onChange && onChange(newState,'upload');
		enqueueSnackbar('Report Uploaded.', {
			variant:'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};

	// const handleCloudRemove = async ()=>{
	// 	try {
	// 		const newState = await reportingApi.unPublishReport( report );
	// 		onChange && onChange( newState, 'unpublish' );
	// 		enqueueSnackbar( 'Report Unpublished.', {
	// 			variant: 'success',
	// 			anchorOrigin: {
	// 				vertical: 'top',
	// 				horizontal: 'center'
	// 			}
	// 		} );
	// 	} catch(e) {
	// 		console.error(e);
	// 	}
	// };
	const loadingButtonSx = {
		printDisplay: 'none',
		padding: '0rem .2rem', // Adjust padding as needed
		minWidth: 'auto', // Allow for dynamic width,
		fontSize: 'small'
	};
	const reportDate = getPreviousThursday(new Date());
	return (
		<>
			{ canUpdate && (canPublish || isWeekAfter(new Date(report?.date), reportDate)) ?
				<LoadingButton sx={ loadingButtonSx } size="small" disabled={ !canUpdate } onClick={ handleEditClick } title={ `Edit ${report.title}` }><EditIcon /></LoadingButton> :
				<LoadingButton sx={ loadingButtonSx } size="small" disabled={ !canUpdate } onClick={ handleEditClick } title={ `View ${report.title}` }><Preview /></LoadingButton>
			}
			<LoadingButton sx={ loadingButtonSx } size="small" disabled={!canPublish || !report.submitted} onClick={handleUpload} loading={reportingApi.isLoading } title={ `Publish ${report.driveLink?.label} to Cloud` } ><CloudUpload/></LoadingButton>
			<LoadingButton sx={ loadingButtonSx } size="small" disabled={!(canPublish) || !report.published} href={report?.published ?? '#'} title={ `Download ${report.driveLink?.label}` }><CloudDownload/></LoadingButton>
			{ report?.id && <ReportDialog
				id='report_card_report_dialog'
				initialState={ report }
				open={ showReportDialog }
				onClose={ handleCloseReportDialog }
				on={ ( state, action ) => setLastDialogAction( { state, action } ) }
				title={ report?.title }
				component={ component }
				helpContent={ <PropertyWeeklyUpdateHelpContent /> }
				canUpdate={ canUpdate && ( canPublish || isWeekAfter( new Date( report?.date ), reportDate ) ) }
				canPublish={ canPublish }
				canDelete={ canDelete }
			/> }
		</>
	);
};
